var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitUpdate.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }),
      ]),
      _c("div", { staticClass: "row d-flex justify-content-end" }, [
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c("ButtonsActionsFooter", {
              attrs: {
                variant: "success",
                submited: _vm.submited,
                text: "Salvar",
                subtext: "Aguarde...",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.updating
        ? _c(
            "p",
            { staticClass: "mb-0te text-success" },
            [
              _c("b-spinner", {
                staticClass: "mr-1",
                attrs: { label: "Loading...", small: "" },
              }),
              _vm._v(" Atualizando... "),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }