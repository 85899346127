<template>
  <b-form @submit.prevent="submitUpdate">
    <div class="card">
      <div class="card-body">
      </div>
    </div>

    <div class="row d-flex justify-content-end">
      <div class="col-md-3">
        <ButtonsActionsFooter
          variant="success"
          :submited="submited"
          text="Salvar"
          subtext="Aguarde..."
        />
      </div>
    </div>

    <p v-if="updating" class="mb-0te text-success">
      <b-spinner label="Loading..." small class="mr-1" />
      Atualizando...
    </p>
  </b-form>
</template>

<script>
import {
  BCard,
  BCol,
  BSpinner,
  BListGroupItem,
  BCardBody,
  BListGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BForm,
  BRow,
  BTabs,
  BTab,
} from "bootstrap-vue";

import draggable from "vuedraggable";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      nextProductId: 0,
      nextContestId: 0,
      product: [],
      contest: [],
      updating: false,
      submited: false,
    };
  },
  components: {
    AppCollapse,
    BSpinner,
    AppCollapseItem,
    BCard,
    BListGroupItem,
    BCardBody,
    BListGroup,
    draggable,
    BFormInput,
    BInputGroup,
    BButton,
    BRow,
    BForm,
    BTabs,
    BCol,
    BTab,
    ButtonsActionsFooter,
  },
  created() {
    this.getData()
  },
  methods: {
    submitUpdate() {      
      this.$v.$touch();
      this.submited = true;

      if (this.contest.length && this.product.length) {
        this.$store
          .dispatch("Faq/create", { contest: this.contest, product: this.product })
          .then(() => {
            this.notify(
              "Operação realizada com sucesso!",
              "UserCheckIcon",
              "success",
              "",
            );
          })
          .catch((err) => {
            this.submited = false;
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", 'Verifique os campos obrigatórios');
      }
    },
    async getData() {
      this.$store.dispatch("Faq/all").then((res) => {
        this.product = res.product
        this.contest = res.contest
      });
    },
    onChangeSort(event) {
      if (event.moved) {
        this.updating = true;
      }
    },
    repeateAgain(type) {
      if (type === 'product') {
        this.product.push({
          id: this.nextProductId + this.product.length,
          title: '',
          description: '',
          category: 'product',
        });
      } else {
        this.contest.push({
          id: this.nextProductId + this.contest.length,
          title: '',
          description: '',
          category: 'contest',
        });
      }
    },
    removeItem(index) {
      this.product.splice(index, 1);
    },
  },
  validations: {
    contest: {
      title: {
        required,
      },
      description: {
        required,
      },
    },
    product: {
      title: {
        required,
      },
      description: {
        required,
      },
    },
  }
};
</script>
